import RouterConstants from "@/common/constants/app.constants";

export default class ApiRouterConstants {
  static API_CAMPAIGNS = `${RouterConstants.API_URL}line_crm/campaigns`;
  static API_CAMPAIGN_SEGMENTS = `${RouterConstants.API_URL}line_crm/segments/categories`;
  static API_CAMPAIGN_SEGMENTS_CATEGORIES = `${RouterConstants.API_URL}line_crm/segments/`;
  static API_CLINICS = `${RouterConstants.API_URL}api/v1/clinics`;
  static API_CLIENT = `${RouterConstants.API_URL}api/v1/clinics/client`;
  static API_CONTACT = `${RouterConstants.API_URL}api/v1/clinics/contacts`;
  static API_SIGN_IN = `${RouterConstants.API_URL}api/v1/clinics/sign_in`;
  static API_FORGOT_PASSWORD = `${RouterConstants.API_URL}api/v1/clinics/password`;
  static API_ZIPCODES = `${RouterConstants.API_URL}api/v1/clinics/zipcodes`;
  static API_CUSTOMERS = `${RouterConstants.API_URL}api/v1/clinics/customers`;
  static API_HOTPEPPER_CUSTOMERS = `${RouterConstants.API_URL}api/v1/clinics/hotpeppers/customers`;
  static API_RICH_MENU_TEMPLATES = `${RouterConstants.API_URL}api/v1/clinics/lines/menu_templates`;
  static API_RICH_MENUS = `${RouterConstants.API_URL}api/v1/clinics/lines/rich_menus`;
  static API_SCHEDULED_MESSAGES = `${RouterConstants.API_URL}line_crm/scheduled_messages`;
  static API_ACCOUNTS = `${RouterConstants.API_URL}api/v1/clinics/lines/accounts`;
  static API_UNLINKED_ACCOUNTS = `${this.API_ACCOUNTS}/unlinked`;
  static API_LIVE_CHAT_MESSAGES = `${RouterConstants.API_URL}api/v1/clinics/lines/live_chat_messages`;
  static API_STAFFS = `${RouterConstants.API_URL}api/v1/clinics/staffs`;
  static API_MENUS = `${RouterConstants.API_URL}api/v1/clinics/medical_menus`;
  static API_OPTIONS = `${RouterConstants.API_URL}api/v1/clinics/medical_options`;
  static API_TAGS = `${ApiRouterConstants.API_CLINICS}/tags`;
  static API_CLINIC_ROUTINE = `${ApiRouterConstants.API_CLINICS}/clinic_business_day_setting`;
  static API_APPLY_PREV_MONTH = `${ApiRouterConstants.API_CLINICS}/clinic_business_day_setting/apply_prev_month`;
  static API_BOOKING_SETTING = `${ApiRouterConstants.API_CLINICS}/clinic_booking_settings`;
  static API_BOOKINGS = `${this.API_CLINICS}/bookings`;
  static API_REQUEST_BOOKINGS = `${this.API_CLINICS}/request_bookings`;
  static API_STAFF_SHIFT = `${ApiRouterConstants.API_CLINICS}/clinic_staff_shift`;
  static API_STAFF_SHIFT_APPLY_PREV_MONTH = `${ApiRouterConstants.API_CLINICS}/clinic_staff_shift/apply_prev_month`;
  static API_STAFF_SHIFTS = `${ApiRouterConstants.API_CLINICS}/clinic_staff_shifts`;
  static API_SLOTS_FREE = `${RouterConstants.API_URL}api/v1/clinics/bookings/slots_free`;
  static API_MEDICAL_MENU_CATEGORIES = `${RouterConstants.API_URL}api/v1/clinics/medical_menu_categories`;
  static API_LIVE_MESSAGE_TEMPLATES = `${RouterConstants.API_URL}api/v1/clinics/live_message_templates`;
  static API_DISTRIBUTION_GROUPS = `${RouterConstants.API_URL}line_crm/distribution_groups`;
  static API_MANUAL_TAGS = `${RouterConstants.API_URL}api/v1/clinics/manual_tags`;
  static API_CLIENT_DETAILS = `${RouterConstants.API_URL}api/v1/clinics/client_details`;
  static API_BOOKING_CUSTOMERS = `${RouterConstants.API_URL}api/v1/clinics/bookings/customers`;
  static API_BOOKING_MENUS = `${RouterConstants.API_URL}api/v1/clinics/bookings/medical_menus`;
  static API_LINE_NOTIFICATION_MESSAGES = `${RouterConstants.API_URL}api/v1/clinics/line_notification_messages`;
  static API_SERVICE_TAGS = `${RouterConstants.API_URL}api/v1/clinics/service_tags`;
}
