import { useStorage } from "@vueuse/core";

const defaultState = () => {
  return {
    bookingDuration: useStorage("bookingDuration", 0, sessionStorage),
    option: useStorage("option", 0, sessionStorage),
    optionRequire: useStorage("optionRequire", false, sessionStorage),
  };
};

const BookingSetting = {
  namespaced: true,
  state: defaultState,
  mutations: {
    setBookingSetting(state: any, setting: any) {
      state.bookingDuration = setting.booking_duration;
      state.option = setting.option;
      state.optionRequire = setting.option_require;
    },
  },
  getters: {
    bookingDuration: (state: any) => {
      return state.bookingDuration;
    },
    option: (state: any) => {
      return state.option;
    },
    optionRequire: (state: any) => {
      return state.optionRequire;
    },
  },
};

export default BookingSetting;
